<template>
  <!-- 品牌文化 -->
  <div class="px1200">
    <!-- bt -->
    <div class="shouye_biapt shouye_biapt_ge">
      <div class="qian">品</div>
      <div class="nimen_ge">
        <p>品 牌 文 化</p>
        <span>Brand culture</span>
      </div>
    </div>
    <div class="nimen_gea">
      <p>{{ firstReservice.description }}</p>
      <my-link href="/reservice">查看全部+</my-link>
    </div>
  </div>
<!--  <div class="home-about">-->
<!--    <div class="wrap-lg">-->
<!--      <div class="">-->
<!--        <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">企业简介</span></div>-->
<!--      </div>-->
<!--      <div class="home-about-box" v-loading="loading">-->
<!--        <div class="video-box-left">-->
<!--          <video :src="httpPath + firstReservice.videoUrl" controls="controls"-->
<!--                 style="width: 100%; height: 100%; display: block; object-fit: fill;"></video>-->
<!--        </div>-->
<!--        <div class="video-box-right">-->
<!--          <div class="video-right-content">-->
<!--            <div class="box-right-top">-->
<!--              <div class="box-right-title beyond-hide" >-->
<!--                <span :title="firstReservice.title">{{ firstReservice.title }}</span>-->
<!--              </div>-->
<!--              <div class="box-right-content">-->
<!--                <span :title="firstReservice.description">-->
<!--                   {{ firstReservice.description }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="box-right-bottom">-->
<!--              <div class="right-bottom-center">-->
<!--                <div class="bottom-center-item" v-for="item in firstReservice.list" :key="item.id">-->
<!--                  <div style="margin: 10px;">-->
<!--                    <div class="center-item-title beyond-hide">-->
<!--                      <span :title="item.title">{{ item.title }}</span>-->
<!--                    </div>-->
<!--                    <div :title="item.content" class="center-item-content"-->
<!--                         v-html="item.content">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</template>

<script>
import { firstGetLists } from '@/api/recommend/testing'

export default {
  name: 'recommendService',
  data () {
    return {
      httpPath: '',
      firstReservice: {},
      currentindex: 1
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.initData()
  },
  methods: {
    initData () {
      let param = {
        categoryCode: '1'
      }
      this.loading = true
      firstGetLists(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData && result.bizData.length > 0) {
            if (result.bizData.length > 0) {
              for (var i = 0; i < result.bizData.length; i++) {
                if (result.bizData[i].imgUrl !== '') {
                  var arr = result.bizData[i].imgUrl.split(',')
                  result.bizData[i].imgUrl = arr
                }
              }
            }
            this.firstReservice = result.bizData[0]
          }
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home-about {
  .home-about-box {
    display: inline-flex;

    .video-box-left {
      width: 611px;
      height: 420px;
      background-color: black;
      border: 1px solid #f6b95e;

      video {
        width: 100%;
        height: 100%;
      }

    }

    .video-box-right {
      width: 605px;

      .video-right-content {
        //margin-left: 13px;
        width: 100%;

        .box-right-top {
          width: 100%;
          height: 210px;
          margin-left: 8px;
          color: #4f080e;

          .box-right-title {
            font-weight: bold;
            font-size: 20px;
          }

          .box-right-content {
            height: 162px;
            font-size: 14px;
            line-height: 30px;
            padding: 10px 8px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .box-right-bottom {
          width: 100%;
          height: 210px;
          display: inline-flex;

          .right-bottom-center {
            width: 100%;

            .bottom-center-item {
              background-color: #4f080e;
              width: 284px;
              height: 90px;
              float: left;
              margin: 8px 8px;
              border: 1px solid #f6b95e;

              .center-item-title {
                font-size: 18px;
                font-weight: 700;
                color: #F6B95E;
              }

              .center-item-content {
                font-size: 14px;
                color: #F6B95E;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap
              }
            }
          }
        }
      }
    }
  }
}
</style>
