import axios from '@/api/api-request'

const projectUrl = '/webAdsPicture'

export const getLists = (params) => {
  return axios.request({
    url: projectUrl + '/getLists.do',
    method: 'post',
    params: params
  })
}
