import axios from '@/api/api-request'

const projectUrl = '/mallGoods'

// 商品管理-获取分页列表
export const list = (data) => {
  return axios.request({
    url: projectUrl + '/list.do',
    method: 'post',
    data: data
  })
}
// 商品管理-首页显示-获取分页列表
export const firstList = (data) => {
  return axios.request({
    url: projectUrl + '/firstList.do',
    method: 'post',
    data: data
  })
}
// 商品管理-获取实体信息
export const getInfo = (params) => {
  return axios.request({
    url: projectUrl + '/getInfo.do',
    method: 'post',
    params: params
  })
}
