<template>
  <div>
    <div class="shuff-lg">
      <div class="side-content">
        <!-- 中间轮播 -->
        <div v-show="!showMenuDiv" class="side-center" >
          <el-carousel height="400px;" ref="nop" arrow="never" :autoplay="type">
            <div @touchstart="start($event)" @touchmove="move($event)">
              <el-carousel-item v-for="(banner) in banners" :key="banner.id">
                <a v-if="banner.targetUrl" target="_blank">
                  <img style="height: 100%;width: 100%;" :src="httpPath + banner.imgUrl"/>
                </a>
                <img v-if="!banner.targetUrl" style="height: 100%;width: 100%;" :src="httpPath + banner.imgUrl"/>
              </el-carousel-item>
            </div>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLists as adsPicList } from '@/api/ads/adsPics'
export default {
  name: 'topBanner',
  data: function () {
    return {
      httpPath: '',
      showMenuDiv: false,
      bannerLoading: false,
      serviceItems: this.commonUtils.getDictPara('service_type'),
      banners: [],
      type: true,
      startX: '',
      startY: '',
      moveY: '',
      moveX: '',
      startTime: ''
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.serviceItems.forEach(item => {
      let newName = item.name
      if (newName.split('_').length === 2) {
        item.icon = newName.split('_')[0]
        item.name = newName.split('_')[1]
      }
      item.serviceTypes = this.commonUtils.getDictPara(item.code + '_type')
    })
    this.initData()

    // 轮播行业公告
    this.$nextTick(function () {
      this.timer = setInterval(this.changeTab, 5000)
    })
  },
  methods: {
    // 轮播行业公告定时器函数
    changeTab () {
      if (!this.noLb) {
        if (this.activeName === '3') {
          this.activeName = '1'
        } else {
          this.activeName = (parseInt(this.activeName) + 1) + ''
        }
      }
    },
    initData: function () {
      this.adsPicList()
    },
    adsPicList () {
      let param = {}
      param.categoryCode = '1'
      this.bannerLoading = true
      adsPicList(param).then(res => {
        this.bannerLoading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.banners = result.bizData
        }
      }).catch(ex => {
        this.bannerLoading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    },
    start (e) {
      this.startX = e.touches[0].clientX
      this.startY = e.touches[0].clientY
    },
    move (e) {
      this.moveX = e.touches[0].clientX
      this.moveY = e.touches[0].clientY
      var nowtime = new Date().getTime()
      if (this.startTime === undefined || nowtime > this.startTime) {
        if (this.startX - this.moveX <= 0) { // 右滑触发
          this.prev()
          return false
        } else {
          this.next()
          return false
        }
      }
    },
    prev () {
      this.$refs.nop.prev()
      this.startTime = new Date().getTime() + 500
    },
    next () {
      this.$refs.nop.next()
      this.startTime = new Date().getTime() + 500
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-carousel__container {
  height: 625px;
}
 .side-content {
    width: 100%;
    display: inline-flex;
    .side-center {
      width: 100%;
      height: 100%;
    }
  }
/deep/.el-carousel__indicator--horizontal {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .2;
  margin: 0 4px;
  padding: 0;
}
/deep/.el-carousel__indicator--horizontal.is-active {
  opacity: 1;
  background: #e60202;
}
/deep/.el-carousel__button {
  display: none;
}
@media (max-width: 1200px) {
  /deep/.el-carousel__container {
    height: 3rem;
  }
}
</style>
