<template>
  <!-- 产品推荐 -->
  <div class="px1200">
    <div class="shouye_biapt shouye_biapt_ge shouye_biapt_ge_A">
      <div class="qian">产</div>
      <div class="nimen_ge">
        <p>产 品 推 荐</p>
        <span>product</span>
      </div>
    </div>
    <div class="yao_meng">
      <div>
        <div v-for="(item, index) in goodsclassif" :key="item.id" class="category-class">
          <span>{{item.name}}:</span>
          <a v-for="category in item.list"
             :key="category.id"
             @click="swichProduct(category, index)"
             :class="(categoryIndexs.indexOf(category.code)>-1)? 'no' : ''">
            {{category.name}}
          </a>
        </div>
      </div>
    </div>
    <div class="nimen_nimen_Ge">
      <ul>
        <li v-for="good in goods" :key="good.id">
          <my-link :href="'/goods/detail?sid='+good.id">
            <img :src="httpPath + good.image" alt="">
            <p :title="good.name">{{good.name}}</p>
          </my-link>
        </li>
      </ul>
    </div>
  </div>
  <!--  -->
<!--  <div class="wrap-lg">-->
<!--    <div class="">-->
<!--      <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">最新系列</span></div>-->
<!--    </div>-->
<!--    <div class="boxs-top clearfix">-->
<!--      <div class="top-more">-->
<!--        <my-link href="/mall">-->
<!--          查看更多-->
<!--          <i class="el-icon-arrow-right"></i>-->
<!--        </my-link>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="list-box" v-loading="loading">-->
<!--      <div class="box-all">-->
<!--        <div v-for="good in otherGoods" :key="good.id" class="box-item">-->
<!--          <my-link :href="'/goods/detail?sid='+good.id">-->
<!--            <div style="width: 100%; height: 270px;">-->
<!--              <img :src="httpPath + good.image" />-->
<!--            </div>-->
<!--            <div class="box-title beyond-hide">-->
<!--              <span :title="good.name">{{good.name}}</span>-->
<!--            </div>-->
<!--          </my-link>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import { firstList } from '@/api/mall/goods'
export default {
  name: 'index',
  data () {
    return {
      httpPath: '',
      searchText: '',
      loading: false,
      goodsclassif: [],
      firstGoods: {},
      goods: [],
      isFirst: true,
      otherGoods: [],
      pageParam: {
        currentPage: 1,
        pageSize: 200,
        total: 10
      },
      categoryIndex: '',
      categoryIndexs: []
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    let cataArr = this.commonUtils.sessionStorageGet('goodsclassif')
    cataArr.forEach(item => {
      item.selectedCates = []
    })
    this.goodsclassif = cataArr
    this.handleSearch()
  },
  methods: {
    handleSearch: function () {
      let param = {}
      if (this.searchText) {
        param.name = this.searchText
      }
      param.classifications = []
      this.goodsclassif.forEach(item => {
        let classif = {}
        classif.classificationCode = item.code
        classif.categorys = []
        if (item.selectedCates.length > 0) {
          item.selectedCates.forEach(sub => {
            classif.categorys.push({
              categoryCode: sub
            })
          })
          param.classifications.push(classif)
        }
      })
      param.page = {
        pageNum: this.pageParam.currentPage,
        pageSize: this.pageParam.pageSize
      }
      this.loading = true
      firstList(param).then(res => {
        var result = res.data
        this.loading = false
        if (result.rtnCode === '0') {
          /* this.firstGoods = {}
          this.goods = []
          this.otherGoods = [] */
          this.goods = result.bizData.splice(0, 6)
          this.pageParam.total = result.total
        } else {
          this.loading = false
          this.$message.error(result.bizMsg)
        }
      }).catch(() => {
        this.$message.error('列表加载失败')
      })
    },
    swichProduct (category, index) {
      this.categoryIndex = category.code
      // eslint-disable-next-line no-unused-vars
      let flag = false
      this.categoryIndexs.forEach((item, i) => {
        if (item === category.code) {
          flag = true
        }
      })
      if (!flag) {
        this.categoryIndexs.push(category.code)
        this.goodsclassif[index].selectedCates.push(category.code)
      } else {
        this.categoryIndexs = this.categoryIndexs.filter(item => item !== category.code)
        this.goodsclassif[index].selectedCates = this.goodsclassif[index].selectedCates.filter(item => item !== category.code)
      }
      // this.goodsclassif[index].selectedCates = this.categoryIndexs
      // this.goodsclassif[index].selectedCates = unique(this.goodsclassif[index].selectedCates)
      this.handleSearch()
    },
    unique (arr) {
      // set本身是具有无序，不能重复的特性
      const set = new Set(arr)
      return [...set]
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-box{
    display: inline-flex;
    .box-all{
      width: 1226px;
    }
  }

  .box-item{
    background: #4f080e;
    float: left;
    margin-right: 10px;
    list-style: none;
    height: 320px;
    width: 294px;
    margin-top: 10px;
    text-align: center;
    border: 1px solid #f6b95e;
    &:hover {
      box-shadow: 0px 0px 15px 0px rgba(2, 91, 75, 0.2);
    }
    a{
      text-decoration:none;
    }
    img {
      display: block;
      height: 100%;
      width: 100%;
    }
    .box-title {
      font-size: 16px;
      font-weight: 400;
      color: #f6b95e;
      line-height: 50px;
    }
  }
  .category-class {
    display: inline-block;
    margin-left: 5px;
  }
</style>
