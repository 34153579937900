<template>
  <div>
    <banner></banner>
<!--    <VideoIntroduce></VideoIntroduce>-->
<!--    <div style="height: 50px;"></div>-->
<!--    <div class="home-center-img">-->
<!--      <img src="@/assets/img/home/u6.jpg">-->
<!--    </div>-->
<!--    <new-serise></new-serise>-->
    <div class="pingp_wenh">
      <recommend-service></recommend-service>
      <new-serise></new-serise>
    </div>
<!--    <VideoIntroduce></VideoIntroduce>-->

    <!--    <honor-index></honor-index>-->
    <news-index></news-index>
  </div>
</template>

<script>
import Banner from './banner'
// import VideoIntroduce from './videoIntroduce'
import NewSerise from './newserise'
import RecommendService from './recommendService'
// import HonorIndex from './honorIndex'
import NewsIndex from './newsIndex'

export default {
  name: 'index',
  // components: { Banner, VideoIntroduce, NewSerise, RecommendService, HonorIndex, NewsIndex }
  components: { Banner, RecommendService, NewSerise, NewsIndex }

  // , RecommendService, ,
}
</script>

<style lang="scss" scoped>
.home-center-img {
  width: 100%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
