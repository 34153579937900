<template>
  <div class="news_shouyek">
    <div class="px1200">
      <div class="lest_nimen">
        <div class="news_list">
          <div class="shouye_biapt shouye_biapt_ge shang_ege">
            <div class="qian">新</div>
            <div class="nimen_ge">
              <p>新 闻 中 心</p>
              <span>news</span>
            </div>
          </div>
          <div class="news_limian_k">
            <div class="left">
              <!-- Swiper -->
              <div class="img_swipes">
                <div class="swiper-wrapper">
                  <div class="swiper-slide"  v-for="item in notices.slice(0, 5)" :key="item.id" >
                    <my-link :href="'/news/detail?sid='+item.id +'&st='+ categoryCode">
                      <img :src="httpPath + item.imgUrl" alt="">
                    </my-link>
                  </div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination" slot="pagination"></div>
              </div>
            </div>
            <div class="list_news">
              <ul>
                <li v-for="(item,index) in notices" :key="index">
                  <my-link :href="'/news/detail?sid='+item.id +'&st='+ categoryCode">
                    <p :title="item.title">{{ item.title }}</p>
                    <span v-if="index === 0" :title="item.description">{{ item.description }}</span>
                  </my-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="div_right">
          <div class="shouye_biapt shouye_biapt_ge shang_ege">
            <div class="qian">联</div>
            <div class="nimen_ge">
              <p>联 系 我 们</p>
              <span>Contact us</span>
            </div>
          </div>
          <ul>
            <li>
<!--              <img src="@/assets/style/images/lianx.jpg" alt="">-->
              <div class="contactus-map" :id="mapId"></div>
<!--              <div class="img" style="background-color: #000;opacity: 0.6;height: 152px;">-->
<!--                <img src="@/assets/style/images/jian_img.png" alt="">-->
<!--                <p>我们在这：<span>{{ commonUtils.getDictName('web_display_config', 'address') }}</span></p>-->
<!--              </div>-->
            </li>
            <li>
              <img src="@/assets/style/images/lianx1.jpg" alt="">
              <div class="img">
                <img src="@/assets/style/images/jian_img1.png" alt="">
                <p>我们电话：<span>{{ commonUtils.getDictName('web_display_config', 'mobile') }}</span></p>
<!--                  <span>办公总机：400-6677937<br>座机：0851-8476866</span></p>-->
              </div>
            </li>
            <li>
              <img src="@/assets/style/images/lianx2.jpg" alt="">
              <div class="img">
                <img src="@/assets/style/images/jian_img2.png" alt="">
                <p>申请加盟：<span>在线申请加盟我们会及时联系您</span></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="wrap-lg">-->
<!--    <div>-->
<!--      <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">新闻资讯</span></div>-->
<!--    </div>-->
<!--    <div class="list-news">-->
<!--      <div class="news-box">-->
<!--        <div v-for="item in notices" :key="item.id" class="news-box-item">-->
<!--          <my-link :href="'/news/detail?sid='+item.id +'&st='+ categoryCode">-->
<!--            <div class="box-item-center">-->
<!--              <div class="box-item-img">-->
<!--                <img :src="httpPath + item.imgUrl" style=" display: block; width: 100%; height: 100%"/>-->
<!--              </div>-->
<!--              <div class="box-item-text">-->
<!--                <div class="box-title beyond-hide">-->
<!--                  <span :title="item.title">{{ item.title }}</span>-->
<!--                </div>-->
<!--                <div class="box-desc multi-hide">-->
<!--                  <span :title="item.description">{{ item.description }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </my-link>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import { firstList } from '@/api/news/industry'
import Swiper from 'swiper'
import loadBMap from '@/utils/map.js'
import commonUtils from '@/utils/common-utils'

export default {
  name: 'index',
  data () {
    return {
      mapId: 'BMap-' + parseInt(Date.now() + Math.random()),
      myMap: null,
      httpPath: '',
      categoryCode: '2',
      searchText: '',
      notices: [],
      loading: false,
      pageParam: {
        currentPage: 1,
        pageSize: 11,
        total: 10
      }
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.mockData()
    this.initMap()
  },
  methods: {
    // 获取客户列表
    mockData: function () {
      this.loading = true
      let param = {}
      param.categoryCode = this.categoryCode
      param.keyWord = this.searchText
      param.page = {
        pageNum: this.pageParam.currentPage,
        pageSize: this.pageParam.pageSize,
        orderBy: 'createTime'
      }
      if (this.categoryCode === '2') {
        this.getIndustryList(param)
      }
    },
    getIndustryList (param) {
      firstList(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.notices = result.bizData.splice(0, 12)
          // 分页赋值
          this.pageParam.total = result.total
          // 在异步请求成功后再定义轮播图即可
          this.$nextTick(() => {
            this.carouselScroll()
          })
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    },
    callFather (parm) {
      this.pageParam.currentPage = parm.currentPage
      this.pageParam.pageSize = parm.pageSize
      this.mockData()
    },
    carouselScroll () {
      // eslint-disable-next-line no-new,no-undef
      new Swiper('.img_swipes', {
        loop: true, // 循环模式选项
        autoplay: true,
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      })
    },
    initMap () {
      loadBMap('uihZTRVFE84P8gxwxKdmBlppBOHQBvFP')
        .then(() => {
          var _MyMap = this.myMap
          const x = commonUtils.getDictName('web_display_config', 'x')
          const y = commonUtils.getDictName('web_display_config', 'y')
          const name = commonUtils.getDictName('web_display_config', 'address')
          _MyMap = new BMap.Map(this.mapId) // 创建Map实例
          var point = new BMap.Point(x, y)
          var marker = new BMap.Marker(point)
          _MyMap.addOverlay(marker)
          _MyMap.centerAndZoom(point, 11) // 初始化地图,设置中心点坐标和地图级别
          // 添加一个名字label
          let nameLab = new BMap.Label(name, {
            offset: new BMap.Size(10, -30),
            position: point
          })
          let labStyle = {
            minWidth: '180px',
            height: '35px',
            lineHeight: '35px',
            textAlign: 'center',
            color: '#2a2222',
            fontSize: '14px',
            backgroundColor: 'none',
            border: '1px solid #00bebebe',
            borderRadius: '4px'
          }
          nameLab.setStyle(labStyle)
          _MyMap.addOverlay(nameLab)
          _MyMap.addControl(
            new BMap.MapTypeControl({
              // eslint-disable-next-line no-undef
              mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
            })
          )
          _MyMap.enableScrollWheelZoom(true)
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-news {
  display: inline-flex;

  .news-box {
    width: 1226px;

    .news-box-item {
      background: #4f080e;
      float: left;
      margin-right: 50px;
      list-style: none;
      height: 150px;
      width: 550px;
      margin-top: 30px;
      text-align: left;
      border: 1px solid #f6b95e;

      .box-item-center {
        display: inline-flex;
        width: 100%;
        height: 100%;

        .box-item-img {
          width: 150px;
          height: 100%;
        }

        .box-item-text {
          width: 370px;
          margin: 20px auto auto 20px;

          .box-title {
            font-size: 18px;
            line-height: 30px;
            color: #f6b95e;
          }

          .box-desc {
            font-size: 14px;
            line-height: 20px;
            color: #f6b95e;
          }
        }
      }
    }
  }
}
.contactus-map {
  height: 152px;
}
.div_right ul li .img p {
  line-height: 28px;
}
.div_right ul li .img p span {
  line-height: 20px;
}
</style>
